<template>

	    <div class="form-group grid grid-cols-12 gap-4" v-bind:for="item.id" >

	    	<div class="col-span-12 md:col-span-8" v-bind:id="item.id">
	    		<el-checkbox v-if="access_restricted == 0" v-model="checkedValue" v-on:click="clickCheckbox"  v-bind:name="item.id.toString()">
	    			<span  v-on:click="clickCheckbox" v-html="naamTranslated"></span>
	    			<Toelichting :item="item"></Toelichting>
	    		</el-checkbox>
	    		<div v-else >
	    			<span  v-html="naamTranslated"></span>
	    			<Toelichting :item="item" ></Toelichting>
	    		</div>
	    	</div>
        <div class="col-span-4 md:col-span-3 justify-between" >
          <Counter v-if="access_restricted == 0&&show_counter"  :item="item" :key="item.id+'counter'"  />
          </div>
	    	<div class="col-span-6 md:col-span-1 justify-between" >
	    		<div class="md:text-right">

	    			<label v-if="access_restricted == 0" v-on:click="clickCheckbox" >
	    				€ {{ item.opties }}
	    			</label>
            <span v-else-if="item.prohibited_by_restriction" class="optie_volgeboekt">{{ $t('aanmelding.volgeboekt') }}. {{$t('aanmelding.wachtlijst_box')}}</span>
	    			<span v-else class="optie_volgeboekt">{{ $t('aanmelding.volgeboekt') }}</span>
	    		</div>

	    	</div>
	    </div>

</template>

<script>
	import language from '../../methods/lang';
	import aanmeldingEntryMethods from '../../methods/AanmeldingEntryMethods';
	import Counter from './CounterComponent';
	import Toelichting from './ToelichtingComponent';
    export default {
    	props: ['item','lang'],
        mounted() {
            console.log('prijscomponent mounted.');
            let store = this.$store;
            this.setChecked();
            this.spanClickEvent();
        },
        computed: {
          naamTranslated: language.naamTranslated,
          toelichtingTranslated: language.toelichtingTranslated,
          access_restricted: function(){
              if(this.item.prohibited_by_restriction){
                 return true;
              }
              return this.item.volgeboekt;
          },
          show_counter: function(){
            if(this.item.show_counter){
              return true;
            }
            return false
          }
        },
        methods: {
        	clickCheckbox: function(event){
        		event.preventDefault();
        		if(this.checkedValue){
        			return aanmeldingEntryMethods.deleteAanmeldingEntry(this);
        		}
        		aanmeldingEntryMethods.createAanmeldingEntry(this,this.item.opties);
        	},
        	setChecked: function(){
        		let currentObj = this;
        		let store = this.$store;
        		if(aanmeldingEntryMethods.aanmeldingEntryExists(this)){
        			this.aanmeldingEntry = aanmeldingEntryMethods.getAanmeldingEntry(this);
        			this.checkedValue = true;
        			return;
        		}
        		this.checkedValue = false;
                this.spanClickEvent();
        	},
        	isChecked: function(){
        		return this.checkedValue;
        	},
            spanClickEvent: function(){
                let currentObj = this;
                //prevent default checkbox behaviour
                if(!document.getElementById(this.item.id)){
                  return;
                }
                if(!document.getElementById(this.item.id).querySelector('span.el-checkbox__input')){
                    return;
                }
                document.getElementById(this.item.id).querySelector('span.el-checkbox__input').removeEventListener('click',currentObj.listener);
                currentObj.listener = function(event){
                  currentObj.clickCheckbox(event);
                }
                document.getElementById(this.item.id).querySelector('span.el-checkbox__input').addEventListener('click',currentObj.listener)
                // $('div#'+this.item.id).off();
                // $('div#'+this.item.id).on('click','.el-checkbox__input',function(event){
                //     currentObj.clickCheckbox(event);
                // })
            }

        },
        data() {
	      return {
	      	aanmeldingEntry: undefined,
	      	checkedValue: false,
          listener: {},
	      }
	    },
		created() {
            let currentObj = this;
		    this.$store.watch(
		      (state, getters) => getters.getAanmeldEntries.models,
		      () => {
                  this.setChecked();
		      },
		    );
		    this.$store.watch(
		      (state, getters) => getters.getCurrentSessieId,
		      () => {
		      	this.setChecked();
		      }
		    );
  		},
  		components: {
  			Counter,
  			Toelichting
  		}
    }
</script>
